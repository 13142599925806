import { FC } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import { APP_ROUTES } from '@lib/route/constants';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { ContentSpacer } from '@templates/common/layout/ContentSpacer';
import { Button } from '@ui/buttons/Button';

export const NotFound: FC = () => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <ContentSpacer isPadded={false}>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        height="100vh"
      >
        <Grid item xs={12} sm={10} md={8} lg={6} xl={6} my={2}>
          <Card variant="outlined">
            <CardContent
              component={Box}
              mx={{ xs: 2, sm: 10 }}
              my={{ xs: 8, sm: 15 }}
            >
              <Grid
                container
                spacing={2}
                alignItems="center"
                textAlign="center"
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h1"
                    component="h1"
                    fontSize={{ xs: 90, sm: 120 }}
                    lineHeight={{ xs: '70px', sm: '90px' }}
                  >
                    {t('404:notFound')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2" component="h2" fontSize={20}>
                    {t('404:title')}
                  </Typography>
                </Grid>
                <Grid item xs={12} onClick={goToHome}>
                  <Button color="primary" variant="contained">
                    {t('404:goHome')}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </ContentSpacer>
  );

  function goToHome() {
    router.push(APP_ROUTES.HOME.path);
  }
};
