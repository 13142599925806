import { makeTranslations } from '@lib/localization/i18n';
import { AvailableLocales } from '@lib/route/types';
import { NextPage } from 'next';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { SSRConfig, useTranslation } from 'next-i18next';

import { HeadAppends } from '@templates/common/layout/page-layout/HeadAppends';
import { NotFound } from '@templates/NotFound';

const Page404: NextPage<
  InferGetStaticPropsType<typeof getStaticProps>
> = () => {
  const { t } = useTranslation();
  const tabTitle = t('404:tabTitle');

  return (
    <>
      <HeadAppends tabTitle={tabTitle} />
      <NotFound />
    </>
  );
};

export const getStaticProps: GetStaticProps<SSRConfig> = async (ctx) => {
  const { translations } = await makeTranslations(
    ctx.locale as AvailableLocales,
    ['404'],
  );

  return {
    props: translations,
  };
};

export default Page404;
